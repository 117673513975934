<template>
  <div>
    <Card>
      <template #title>Заёмщики</template>
      <template #content>
        <AbstractTable
          code="borrowers"
          display-filter="menu"
        >
          <template #uuid="row">
            <IdChip
              :id="row.data.uuid"
              view="borrower_view"
              :view-params="{ uuid: row.data.uuid }"
              toast-template='Идентификатор заёмщика "{v}" скопирован'
            />
          </template>
        </AbstractTable>
      </template>
    </Card>
  </div>
</template>

<script>
import AbstractTable from "@/components/AbstractTable";
import IdChip from "@/components/Common/IdChip.vue";
export default {
  name: "BorrowersTable",
  components: {IdChip, AbstractTable}
}
</script>

<style scoped>

</style>
<template>
  <div class="grid">
    <div class="col-12">
      <BorrowersTable></BorrowersTable>
    </div>
  </div>
</template>

<script>
import BorrowersTable from "@/components/tables/BorrowersTable";
export default {
  name: "Borrowers",
  components: {BorrowersTable}
}
</script>

<style scoped>

</style>